import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const ArrayFilterWidget: WidgetRegistryEntry = {
  name: "ArrayFilter",
  component: () => import("./array-filter-widget.component").then((m) => m.ArrayFilterWidgetComponent),
  configuration: {
    templateName: "Objekt Filter",
    templateDescription: "Zeigt Filter für alle Eigenschaften des Objektes an.",
    icon: "filter"
  }
};
