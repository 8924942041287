import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const ContactDetailWidget: WidgetRegistryEntry = {
  name: "ContactDetail",
  component: () => import("./contact-detail-widget.component").then((m) => m.ContactDetailWidgetComponent),
  configuration: {
    templateName: "Kontakt Details",
    templateDescription: "Zeigt einen Kontakt an.",
    icon: "fa-address-card"
  }
};
