import { DeprecatedActionResult, WidgetRegistryEntry } from "@smallstack/widget-core";
import { OpenEditorBtnWidgetV2Configuration } from "../open-editor-btn-widget-v2/open-editor-btn-widget-v2.component";

export const OpenEditorBtnWidget: WidgetRegistryEntry = {
  name: "OpenEditorBtn",
  component: () => import("./open-editor-btn-widget.component").then((m) => m.OpenEditorBtnWidgetComponent),
  configuration: {
    templateName: "Editor öffnen Button",
    templateDescription:
      "Öffnet einen Dialog mit einem Editor, entweder zum Bearbeiten oder zum Anlegen einer Entität.",
    icon: "task",
    dataSchema: {
      type: "object",
      properties: {
        type: {
          type: "string",
          title: "Datentyp",
          "x-schema-form": {
            widget: "types"
          }
        },
        contextProperty: {
          type: "string",
          title: "Kontext Variable"
        },
        icon: {
          type: "string",
          title: "Icon",
          "x-schema-form": {
            widget: "icon"
          }
        },
        text: {
          type: "string",
          title: "Text"
        },
        btnAsBlock: {
          type: "boolean",
          title: "Button auf voller Breite"
        }
      }
    },
    deprecated: true,
    deprecatedMessage: [{ value: "Bitte verwenden Sie die Version 2 dieses Widgets." }],
    deprecatedActions: [
      {
        buttonTitle: [{ value: "Zu V2 migrieren" }],
        migrationFn: async (oldWidget): Promise<DeprecatedActionResult> => {
          return {
            newWidgetName: "OpenEditorBtnV2",
            newWidgetData: {
              btnText: oldWidget.text,
              btnWidth: oldWidget.btnAsBlock ? "btn-block" : "",
              btnIcon: oldWidget.icon,
              btnType: "btn",
              btnColor: "btn-primary",
              typeDescriptor: {
                typePath: oldWidget.type
              },
              contextProperty: oldWidget.contextProperty
            } as OpenEditorBtnWidgetV2Configuration
          };
        }
      }
    ]
  }
};
