import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const ObjectFilterWidget: WidgetRegistryEntry = {
  name: "ObjectFilter",
  component: () => import("./object-filter-widget.component").then((m) => m.ObjectFilterWidgetComponent),
  configuration: {
    templateName: "Objekt Filter",
    templateDescription: "Zeigt Filter für alle Eigenschaften des Objektes an.",
    icon: "filter"
  }
};
