import { AllWidgetTags, WidgetRegistryEntry } from "@smallstack/widget-core";

export const MappedIconWidget: WidgetRegistryEntry = {
  name: "MappedIcon",
  configuration: {
    templateName: "Icon",
    icon: "large-icons",
    templateDescription: "Zeigt ein Icon an",
    dataSchema: {
      type: "object",
      properties: {
        mapping: {
          title: "Name",
          description: "Der Name des Icons, basierend auf einer Kontext Variable",
          type: "array",
          items: {
            type: "object",
            properties: {
              value: {
                title: "Wert",
                description: "Wert der Kontext Variable",
                type: "string"
              },
              name: {
                title: "Icon",
                description: "Der Name des Icons für diesen Wert",
                type: "string"
              }
            }
          }
        },
        contextVariable: {
          title: "Kontext Variable",
          description: "Die Kontext Variable, in welcher der Wert für das Icon zu finden ist",
          type: "string"
        },
        defaultIcon: {
          title: "Standard Icon",
          description: "Das Icon, welches angezeigt wird, wenn kein passender Wert gefunden wird",
          type: "string"
        },
        size: {
          title: "Größe",
          description: "Die Größe des Icons",
          type: "string",
          default: "24",
          enum: ["10", "12", "16", "20", "24", "32", "48", "64", "72", "96"]
        },
        color: {
          title: "Farbe",
          description: "Die Farbe des Icons",
          type: "string",
          "x-schema-form": { widget: "color" }
        }
      }
    },
    tags: AllWidgetTags
  },
  component: () => import("./mapped-icon-widget.component").then((m) => m.MappedIconWidgetComponent)
};
