import { WIDGET_FILTER_INPUT_I18N } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const I18nFilterWidget: WidgetRegistryEntry = {
  name: WIDGET_FILTER_INPUT_I18N,
  component: () => import("./i18n-filter-widget.component").then((m) => m.I18nFilterWidgetComponent),
  configuration: {
    templateName: "Übersetzungen Filter",
    templateDescription: "Zeigt ein Feld an, welches zum Filtern von Übersetzungen verwendet werden kann.",
    icon: "filter"
  }
};
