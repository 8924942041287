import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const AbsolutelyPositionedContainerWidget: WidgetRegistryEntry = {
  name: "AbsolutelyPositionedContainer",
  component: () =>
    import("./absolutely-positioned-container-widget.component").then(
      (m) => m.AbsolutelyPositionedContainerWidgetComponent
    ),
  configuration: () => import("./configuration").then((m) => m.AbsolutelyPositionedContainerWidgetConfiguration)
};
