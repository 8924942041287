import { SocketDtoDirectionEnum, SocketDtoTypeEnum } from "@smallstack/axios-api-client";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const UnreadMessagesCountWidget: WidgetRegistryEntry = {
  name: "UnreadMessagesCount",
  configuration: {
    icon: "sent",
    templateName: "Anzahl ungelesener Nachrichten",
    templateDescription: "Liefert die Anzahl ungelesener Nachrichten als Event",
    sockets: [{ direction: SocketDtoDirectionEnum.Out, name: "unreadMessagesCount", type: SocketDtoTypeEnum.Number }]
  },
  component: () => import("./unread-messages-count-widget.component").then((m) => m.UnreadMessagesCountWidgetComponent)
};
