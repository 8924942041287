import { WidgetRegistryEntry, WidgetTag } from "@smallstack/widget-core";

export const BackofficeHelpWidget: WidgetRegistryEntry = {
  name: "BackofficeHelpPage",
  configuration: {
    templateName: "Backoffice Help Page",
    icon: "help",
    templateDescription:
      "Nutzen Sie dieses Widget um in ihrem CBO eine Hilfsseite anzeigen zu können. Die Hilfsseite können Sie bequem als CMS Seite erstellen.",
    tags: [WidgetTag.CBO],
    dataSchema: {
      type: "object",
      properties: {
        cmsPageId: {
          title: "CMS Page ID",
          description: "Die ID der CMS Seite die den Hilfstext enthält und angezeigt werden soll",
          type: "string"
        }
      }
    }
  },
  component: () => import("./backoffice-help-widget.component").then((m) => m.BackofficeHelpWidgetComponent)
};
