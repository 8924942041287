import { WIDGET_FORM_INPUT_HTML_SIZING } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const MessengerWidget: WidgetRegistryEntry = {
  name: "Messenger",
  configuration: {
    templateName: "Messenger",
    templateDescription:
      "Ein Messenger, der es erlaubt, mit anderen Benutzern zu kommunizieren. Es können auch Nachrichten von Projekten oder Resellern angezeigt werden.",
    icon: "sent",
    ssr: false,
    dataSchema: {
      type: "object",
      properties: {
        showChatters: {
          type: "boolean",
          title: "Chatter anzeigen",
          description: "Sollen die Chatter angezeigt werden?",
          default: true
        },
        showTo: {
          type: "boolean",
          title: "Anzeigen, mit wem man gerade chattet",
          default: true
        },
        toId: {
          type: "string",
          title: "Initialer Chatpartner",
          description: "Welcher Chatpartner soll initial angezeigt werden?"
        },
        fromId: {
          type: "string",
          title: "Sender",
          description: "Welche Person soll als Sender verwendet werden?"
        },
        height: {
          type: "string",
          title: "Höhe",
          description: "Wie hoch soll der Messenger sein?",
          default: "400px",
          "x-schema-form": {
            inputWidget: WIDGET_FORM_INPUT_HTML_SIZING
          }
        }
      }
    }
  },
  component: () => import("./messenger-widget.component").then((m) => m.MessengerWidgetComponent)
};
