import { AllWidgetTags, WidgetRegistryEntry } from "@smallstack/widget-core";

export const StoreConfiguratorWidget: WidgetRegistryEntry = {
  name: "StoreConfigurator",
  configuration: {
    templateName: "Store Konfiguration",
    templateDescription: "Kann verwendet werden, um einen globalen Store zu konfigurieren",
    icon: "data-configuration",
    tags: AllWidgetTags,
    dataSchema: {
      type: "object",
      properties: {
        typePath: {
          type: "string",
          title: "Datentyp",
          "x-schema-form": {
            widget: "types"
          }
        },
        loadStoreOnPageLoad: {
          type: "boolean",
          default: false,
          title: "Store bei Seitenaufruf laden?"
        },
        resetStoreOnPageLoad: {
          type: "boolean",
          default: false,
          title: "Store beim Seitenaufruf zurücksetzen?",
          description: ""
        }
      }
    }
  },
  component: () => import("./store-configurator-widget.component").then((m) => m.StoreConfiguratorWidgetComponent)
};
