import { Injectable } from "@angular/core";
import { DraftService } from "@smallstack/typesystem-client";

@Injectable({ providedIn: "root" })
export class BackofficeDraftService extends DraftService {
  protected override getDraftPrefix(): string {
    // const tenantId = currentProjectId$.value ?? currentCompanyId$.value;
    // if (!tenantId) throw new Error("No currentProjectId or currentcompanyId set!");
    return `${super.getDraftPrefix()}/`; // ${tenantId}
  }
}
