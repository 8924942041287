import { WidgetRegistryEntry, WidgetTag } from "@smallstack/widget-core";

export const LastProjectWidget: WidgetRegistryEntry = {
  name: "LastProject",
  configuration: {
    templateName: "Letztes Projekt",
    icon: "web-design",
    templateDescription: "Zeigt das zuletzt geöffnete Projekt an.",
    tags: [WidgetTag.DASHBOARD]
  },
  component: () => import("./last-project-widget.component").then((m) => m.LastProjectWidgetComponent)
};
