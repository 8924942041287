import { WIDGET_FILTER_INPUT_SELECT } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const SelectFilterWidget: WidgetRegistryEntry = {
  name: WIDGET_FILTER_INPUT_SELECT,
  component: () => import("./select-filter-widget.component").then((m) => m.SelectFilterWidgetComponent),
  configuration: {
    templateName: "Auswahl Filter",
    templateDescription: "Filtert nach Auswahl verschiedener Werte",
    icon: "filter"
  }
};
