import { WidgetRegistry } from "@smallstack/widget-core";
import { AnytimeTodosWidget } from "./lib/anytime-todos-widget";
import { CreateNoteWidget } from "./lib/create-note-widget";
import { SchedulingRuleDisplayWidget } from "./lib/scheduling-rule-display-widget";
import { TodoAgendaWidget } from "./lib/todo-agenda-widget";
import { TodoTemplateOwnerReplacementEditorWidget } from "./lib/todo-template-owner-replacement-editor-widget";
import { WorklogLikeTimerWidget } from "./lib/worklog-like-timer-widget";
import { WorklogWidget } from "./lib/worklog-widget";

export function addTodoWidgets(widgetRegistry: WidgetRegistry): void {
  widgetRegistry.addWidget(TodoAgendaWidget);
  widgetRegistry.addWidget(CreateNoteWidget);
  widgetRegistry.addWidget(WorklogWidget);
  widgetRegistry.addWidget(WorklogLikeTimerWidget);
  widgetRegistry.addWidget(AnytimeTodosWidget);
  widgetRegistry.addWidget(SchedulingRuleDisplayWidget);
  widgetRegistry.addWidget(TodoTemplateOwnerReplacementEditorWidget);
}
