import { TYPE_OPT_INS, WIDGET_FORM_INPUT_SELECT } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const ConsentWidget: WidgetRegistryEntry = {
  name: "Consent",
  configuration: {
    templateName: "Einwilligung erteilen",
    templateDescription:
      "Mit diesem Widget können Sie Ihre Benutzer Einwilligungen erteilen lassen. Erst nach erfolgter Einwilligung wird der Inhalt angezeigt.",
    dataSchema: {
      type: "object",
      properties: {
        optInId: {
          type: "string",
          title: "Einwilligung",
          "x-schema-form": {
            inputWidget: WIDGET_FORM_INPUT_SELECT,
            type: TYPE_OPT_INS
          }
        },
        childWidget: {
          type: "object",
          title: "Widget",
          "x-schema-form": {
            widget: "widget"
          }
        }
      }
    }
  },
  component: () => import("./consent-widget.component").then((module) => module.ConsentWidgetComponent)
};
