import { SocketDtoDirectionEnum, SocketDtoTypeEnum } from "@smallstack/axios-api-client";
import { WIDGET_FORM_INPUT_SELECT } from "@smallstack/typesystem";
import { WidgetRegistryEntry, WidgetTag } from "@smallstack/widget-core";

export const CustomerBackofficeWidget: WidgetRegistryEntry = {
  name: "CustomerBackoffice",
  configuration: {
    templateName: "Kunden Backoffice",
    templateDescription: "Zeigt eine Liste mit allen Kunden an.",
    icon: "user",
    sockets: [{ direction: SocketDtoDirectionEnum.In, name: "createCustomer", type: SocketDtoTypeEnum.Undefined }],
    tags: [WidgetTag.CBO],
    dataSchema: {
      type: "object",
      properties: {
        columnsShown: {
          type: "array",
          title: "Angezeigte Spalten",
          default: [
            "avatar",
            "email",
            "profile.firstName",
            "profile.lastName",
            "validated",
            "roles",
            "createdAt",
            "actions"
          ],
          "x-schema-form": {
            inputWidget: WIDGET_FORM_INPUT_SELECT,
            multiple: true,
            values: [
              { label: "Profilbild", value: "avatar" },
              { label: "E-Mail", value: "email" },
              { label: "Benutzername", value: "username" },
              { label: "Vorname", value: "profile.firstName" },
              { label: "Nachname", value: "profile.lastName" },
              { label: "Validiert", value: "validated" },
              { label: "Aktiv", value: "active" },
              { label: "Rollen", value: "roles" },
              { label: "Erstellt", value: "createdAt" },
              { label: "Aktionen", value: "actions" }
            ]
          }
        }
      }
    }
  },
  component: () => import("./customer-backoffice-widget.component").then((m) => m.CustomerBackofficeWidgetComponent)
};
