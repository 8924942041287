import { WIDGET_FORM_INPUT_NUMBER } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const NumberInputWidget: WidgetRegistryEntry = {
  name: WIDGET_FORM_INPUT_NUMBER,
  component: () => import("./number-input-widget.component").then((m) => m.NumberInputWidgetComponent),
  configuration: {
    templateName: "Nummern Feld",
    templateDescription: "Zeigt ein Feld in welches eine Nummer eingegeben werden kann.",
    icon: "rename"
  }
};
