import { AllWidgetTags, WidgetRegistryEntry } from "@smallstack/widget-core";

export const SyncStatusWidget: WidgetRegistryEntry = {
  name: "SyncStatus",
  configuration: {
    templateName: "Offline Synchronisierungsstatus",
    templateDescription: "Zeigt den Status aller Offline Synchronisierungen an",
    icon: "synchronize--v1",
    tags: AllWidgetTags
  },
  component: () => import("./sync-status-widget.component").then((m) => m.SyncStatusWidgetComponent)
};
