import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const ServiceWorkerVersionStatusWidget: WidgetRegistryEntry = {
  name: "ServiceWorkerVersionStatus",
  component: () =>
    import("./service-worker-version-status-widget.component").then((m) => m.ServiceWorkerVersionStatusWidgetComponent),
  configuration: {
    icon: "fa-rotate-exclamation",
    templateName: "Service Worker Status",
    templateDescription: "Zeigt den Status des Service Workers an und ermöglicht ein Neuladen der Seite."
  }
};
