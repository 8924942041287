import { WIDGET_FILTER_INPUT_TIME_SPAN } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const TimeSpanFilterWidget: WidgetRegistryEntry = {
  name: WIDGET_FILTER_INPUT_TIME_SPAN,
  component: () => import("./time-span-filter-widget.component").then((m) => m.TimeSpanFilterWidgetComponent),
  configuration: {
    templateName: "Zeitspanne Eingabefeld",
    templateDescription: "Zeigt Felder, mit welchen eine Zeitspanne eingegeben werden kann.",
    icon: "rename"
  }
};
