import { WIDGET_FORM_INPUT_HTML_SIZING } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const HtmlSizingInputWidget: WidgetRegistryEntry = {
  name: WIDGET_FORM_INPUT_HTML_SIZING,
  component: () => import("./html-sizing-input-widget.component").then((m) => m.HtmlSizingInputWidgetComponent),
  configuration: {
    templateName: "HTML Größenfeld",
    templateDescription:
      "Eine Größe, die entweder als Pixel, Prozent, Viewport-Height, Viewport-Width oder als Bruchteil der verfügbaren Größe angegeben werden kann.",
    icon: "fa-ruler-horizontal"
  }
};
