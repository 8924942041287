import { WIDGET_FILTER_INPUT_BOOLEAN } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const BooleanFilterWidget: WidgetRegistryEntry = {
  name: WIDGET_FILTER_INPUT_BOOLEAN,
  component: () => import("./boolean-filter-widget.component").then((m) => m.BooleanFilterWidgetComponent),
  configuration: {
    templateName: "Boolean Filter",
    templateDescription: "Zeigt einen ja/nein bzw. wahr/falsch Filter an.",
    icon: "fa-square-check"
  }
};
