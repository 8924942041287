import { WIDGET_FORM_INPUT_HTML_SIZING } from "@smallstack/typesystem";
import { WidgetRegistryEntry, WidgetTag } from "@smallstack/widget-core";

export const WorklogWidget: WidgetRegistryEntry = {
  name: "Worklogs",
  configuration: {
    icon: "work",
    tags: [WidgetTag.APPLICATION, WidgetTag.CBO, WidgetTag.CMS, WidgetTag.DASHBOARD],
    templateName: "Arbeitsprotokolle",
    templateDescription: "Zeigt alle Arbeitsprotokolle des aktuell angemeldeten Benutzers.",
    dataSchema: {
      type: "object",
      properties: {
        showDeleteBtn: {
          type: "boolean",
          title: "Löschen Button anzeigen?"
        },
        showEditBtn: {
          type: "boolean",
          title: "Bearbeiten Button anzeigen?"
        },
        height: {
          type: "string",
          title: "Höhe des Widgets in Pixeln",
          "x-schema-form": {
            inputWidget: WIDGET_FORM_INPUT_HTML_SIZING
          }
        }
      }
    }
  },
  component: () => import("./worklog-widget.component").then((m) => m.WorklogWidgetComponent)
};
