import { SocketDtoDirectionEnum, SocketDtoTypeEnum } from "@smallstack/axios-api-client";
import { AllWidgetTags, WidgetRegistryEntry } from "@smallstack/widget-core";

export const ArrayContainerV3Widget: WidgetRegistryEntry = {
  name: "ArrayContainerV3",
  configuration: {
    templateName: "Array Container V3",
    icon: "grid-2",
    templateDescription: "Ein Widget, welches pro Eintrag einer gegebenen Liste ein weiteres Widget anzeigt",
    dataSchema: {
      type: "object",
      properties: {
        contextProperty: {
          type: "string",
          title: "Kontext Liste",
          description:
            "Kontext Variable, in der die Liste an Einträgen gefunden werden kann. Leer lassen, falls der Context selbst die Liste beinhaltet."
        },
        contextOutputProperty: {
          type: "string",
          title: "Kontext Ausgabe Variable",
          description:
            "Kontext Variable, in welcher ein Eintrag an das nächste Widget weitergegeben werden soll. Leer lassen, falls der Kontext selbst den Eintrag beinhalten soll."
        },
        maxItems: {
          type: "number",
          title: "Maximale Anzahl an Elementen",
          description: "Maximale Anzahl an Elementen, welche angezeigt werden sollen. 0 für unbegrenzt.",
          default: 0
        },
        component: {
          title: "Komponente",
          type: "object",
          "x-schema-form": { widget: "cms" }
        },
        flexLayout: {
          title: "Richtung",
          type: "string",
          default: "row",
          enum: ["row", "col"]
        },
        gap: {
          title: "Zwischenabstand",
          type: "number",
          default: 3
        },
        wrap: {
          title: "Umbrechen",
          type: "boolean",
          default: true
        },
        emptyTemplate: {
          title: "Keine Elemente gefunden",
          description: "Diese Komponente wird angezeigt, falls keine Elemente gegeben sind.",
          type: "object",
          "x-schema-form": { widget: "widget" }
        }
      }
    },
    tags: AllWidgetTags,
    sockets: [{ direction: SocketDtoDirectionEnum.In, name: "models", type: SocketDtoTypeEnum.Objectarray }]
  },
  component: () => import("./array-container-widget-v3.component").then((m) => m.ArrayContainerWidgetV3Component)
};
