import { WIDGET_FORM_INPUT_TIME_SPAN } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const TimeSpanInputWidget: WidgetRegistryEntry = {
  name: WIDGET_FORM_INPUT_TIME_SPAN,
  component: () => import("./time-span-input-widget.component").then((m) => m.TimeSpanInputWidgetComponent),
  configuration: {
    templateName: "Zeitspanne Eingabefeld",
    templateDescription: "Zeigt Felder, mit welchen eine Zeitspanne eingegeben werden kann.",
    icon: "rename"
  }
};
