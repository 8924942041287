import { addCompoundWidgets } from "@smallstack/compound-widgets";
import { addDataWidgets } from "@smallstack/data-widgets";
import { addEmployeeWidgets } from "@smallstack/employee-widgets";
import { addFormWidgets } from "@smallstack/form-widgets";
import { addInfoScreenWidgets } from "@smallstack/info-screen-widgets";
import { addLayoutWidgets } from "@smallstack/layout-widgets";
import { addOtherWidgets } from "@smallstack/other-widgets";
import { addTodoWidgets } from "@smallstack/todo-widgets";
import { addUserWidgets } from "@smallstack/user-widgets";
import { WidgetRegistry } from "@smallstack/widget-core";

export function addAllWidgets(widgetRegistry: WidgetRegistry) {
  addLayoutWidgets(widgetRegistry);
  addTodoWidgets(widgetRegistry);
  addFormWidgets(widgetRegistry);
  addOtherWidgets(widgetRegistry);
  addDataWidgets(widgetRegistry);
  addUserWidgets(widgetRegistry);
  addInfoScreenWidgets(widgetRegistry);
  addCompoundWidgets(widgetRegistry);
  addEmployeeWidgets(widgetRegistry);
}
