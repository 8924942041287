import { WIDGET_FORM_INPUT_DATE_TIME } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const DateTimeInputWidget: WidgetRegistryEntry = {
  name: WIDGET_FORM_INPUT_DATE_TIME,
  component: () => import("./date-time-input-widget.component").then((m) => m.DateTimeInputWidgetComponent),
  configuration: {
    templateName: "Datum mit Uhrzeit Feld",
    templateDescription: "Zeigt Felder für Datum und Uhrzeit an.",
    icon: "rename"
  }
};
