import { WIDGET_FILTER_INPUT_DATE_STRING } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const DateStringFilterWidget: WidgetRegistryEntry = {
  name: WIDGET_FILTER_INPUT_DATE_STRING,
  component: () => import("./date-string-filter-widget.component").then((m) => m.DateStringFilterWidgetComponent),
  configuration: {
    templateName: "Datum als Zeichenkette Filter",
    templateDescription:
      "Zeigt Felder an, welche zum Filtern von einem Datum als Zeichenkette (z.B. 2023-08-26) verwendet werden können.",
    icon: "filter"
  }
};
