import { WidgetRegistryEntry, WidgetTag } from "@smallstack/widget-core";

export const BackofficeNavigationIconWidget: WidgetRegistryEntry = {
  name: "BackofficeNavigationIcon",
  configuration: {
    dataSchema: {
      properties: {
        icon: {
          type: "string",
          title: "Icon",
          "x-schema-form": { widget: "icon" }
        },
        link: {
          type: "string",
          title: "Link",
          description: 'Beispiel: "/companies/test/projects/test/cms/pages"'
        }
      }
    },
    tags: [WidgetTag.ICON],
    templateName: "Backoffice Navigation Icon",
    templateDescription: "Kann in der Backoffice Navigation als Shortcut Icon verwendet werden.",
    icon: "link"
  },
  component: () =>
    import("./backoffice-navigation-icon-widget.component").then((m) => m.BackofficeNavigationIconWidgetComponent)
};
