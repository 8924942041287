import { TypeSchema } from "@smallstack/typesystem";
import { AllWidgetTags, WidgetRegistryEntry } from "@smallstack/widget-core";
import { format, subQuarters } from "date-fns";

export const AnonymizedStatsWidget: WidgetRegistryEntry = {
  name: "AnonymizedStats",
  configuration: {
    dataSchema: async () => {
      const quarters = [0, 1, 2, 3].map((i) => format(subQuarters(Date.now(), 1), "QQQ-yyyy"));

      return {
        type: "object",
        properties: {
          dataType: {
            type: "string",
            "x-schema-form": {
              widget: "types"
            }
          } as TypeSchema,
          quarters: {
            type: "array",
            items: {
              type: "string",
              enum: quarters
            },
            minItems: 1,
            maxItems: 25
          }
        }
      };
    },
    icon: "anonymous-mask",
    tags: AllWidgetTags,
    templateName: "Anonymisierungsstatistik",
    templateDescription: "Zeigt den aktuellen Status der Anonymisierung"
  },
  component: () => import("./anonymized-stats-widget.component").then((m) => m.AnonymizedStatsWidgetComponent)
};
