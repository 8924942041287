import { WIDGET_FORM_INPUT_WIDGET_TREE } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const WidgetTreeInputWidget: WidgetRegistryEntry = {
  name: WIDGET_FORM_INPUT_WIDGET_TREE,
  component: () => import("./widget-tree-input-widget.component").then((m) => m.WidgetTreeInputWidgetComponent),
  configuration: {
    templateName: "Widget Baum Feld",
    templateDescription: "Mit diesem Feld können ganze Widget Bäume eingegeben werden kann.",
    icon: "fa-cube"
  }
};
