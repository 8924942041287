import { BUTTON_WIDGET_CONFIGURATION_SCHEMA_PROPERTIES } from "@smallstack/core-common";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const PatchArrayFormWidget: WidgetRegistryEntry = {
  name: "PatchArrayForm",
  configuration: {
    templateName: "Add to Array and Patch",
    templateDescription:
      "Ein Widget, welches ein Formular anzeigt, um ein Element zu einem Array hinzuzufügen, welches beim speichern gepatcht wird.",
    icon: "add",
    dataSchema: {
      type: "object",
      properties: {
        id: {
          type: "string",
          title: "ID",
          description: "Die ID des Widgets, ist context aware."
        },
        typePath: {
          type: "string",
          title: "Datentyp",
          "x-schema-form": {
            widget: "types"
          }
        },
        path: {
          type: "string",
          title: "Pfad zum Array",
          description:
            "Der Name der Variable, welche das Array enthält, welches erweitert werden soll. Siehe https://www.npmjs.com/package/fast-json-patch für mehr Informationen."
        },
        schema: {
          type: "string",
          title: "Formular Schema",
          description: "Das Schema des Formulars, welches das neue Element beschreibt.",
          "x-schema-form": {
            widget: "monaco",
            monacoLanguage: "json"
          }
        },
        saveButton: {
          type: "object",
          title: "Speichern Button",
          properties: BUTTON_WIDGET_CONFIGURATION_SCHEMA_PROPERTIES
        },
        formDefaultValues: {
          type: "object",
          title: "Default Werte",
          description: "Die Default Werte für das Formular.",
          "x-schema-form": {
            widget: "monaco",
            monacoLanguage: "json"
          }
        },
        successMsg: {
          type: "string",
          title: "Erfolgsmeldung",
          description: "Die Meldung, welche bei Erfolg angezeigt werden soll."
        },
        errorMsg: {
          type: "string",
          title: "Fehlermeldung",
          description: "Die Meldung, welche bei einem Fehler angezeigt werden soll."
        }
      }
    }
  },
  component: () => import("./patch-array-form-widget.component").then((m) => m.PatchArrayFormWidgetComponent)
};
