import { WIDGET_FORM_INPUT_DATE_STRING } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const DateStringInputWidget: WidgetRegistryEntry = {
  name: WIDGET_FORM_INPUT_DATE_STRING,
  component: () => import("./date-string-input-widget.component").then((m) => m.DateStringInputWidgetComponent),
  configuration: {
    templateName: "Datum als Text Feld",
    templateDescription:
      "Zeigt ein Datumsfeld an, welches das Datum in der Form Jahr-Monat-Tag (z.B. 2023-03-19) speichert",
    icon: "rename"
  }
};
