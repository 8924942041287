import { WIDGET_FORM_INPUT_GOOGLE_MAPS } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const GoogleMapsInputWidget: WidgetRegistryEntry = {
  name: WIDGET_FORM_INPUT_GOOGLE_MAPS,
  component: () => import("./google-maps-input-widget.component").then((m) => m.GoogleMapsInputWidgetComponent),
  configuration: {
    templateName: "Eingabefeld für Adresse",
    templateDescription:
      "Zeigt Eingabefelder für eine Adresse an. Die Adresse kann auch auf einer Google Map gesucht und ausgewählt werden.",
    icon: "fa-map-location-dot"
  }
};
