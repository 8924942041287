import { WIDGET_FORM_INPUT_FILE_URL } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const FileUrlInputWidget: WidgetRegistryEntry = {
  name: WIDGET_FORM_INPUT_FILE_URL,
  component: () => import("./file-url-input-widget.component").then((m) => m.FileUrlInputWidgetComponent),
  configuration: {
    templateName: "Datei URL Feld",
    templateDescription:
      "Zeigt ein Feld in welches eine Datei URL eingegeben werden kann. Es kann zusätzlich eine Datei ausgewählt werden.",
    icon: "rename"
  }
};
