import { WIDGET_FORM_INPUT_I18N } from "@smallstack/typesystem";
import { WidgetRegistryEntry, WidgetTag } from "@smallstack/widget-core";

export const BackofficeHeaderConfigurationWidget: WidgetRegistryEntry = {
  name: "BackofficeHeaderContainer",
  configuration: {
    templateName: "Backoffice Header Container",
    icon: "document-header",
    templateDescription:
      "Platzieren Sie dieses Widget auf einer Custom Backoffice Seite. Alle Widgets, die Sie in diesem Container platzieren, erscheinen oben rechts im Backoffice.",
    tags: [WidgetTag.CBO],
    dataSchema: {
      type: "object",
      properties: {
        headerText: {
          type: "array",
          "x-schema-form": { inputWidget: WIDGET_FORM_INPUT_I18N },
          title: "Überschrift",
          description: "Der Text, welcher in Ihrem Backoffice in der Kopfzeile erscheint"
        },
        headerSubText: {
          type: "array",
          "x-schema-form": { inputWidget: WIDGET_FORM_INPUT_I18N },
          title: "Text unterhalb Überschrift",
          description: "Der Text, welcher in Ihrem Backoffice in der Kopfzeile unterhalb der Überschrift erscheint"
        },
        headerIcon: {
          type: "string",
          "x-schema-form": { widget: "icon" },
          title: "Icon",
          description: "Wird neben der Überschrift angezeigt. Sollte möglichst das gleiche wie in der Navigation sein."
        },
        backUrl: {
          type: "string",
          title: "Zurück Url",
          description:
            "Falls angegeben, so wird ein zurück Button angezeigt, welcher zur angegebenen, relativen URL navigiert"
        }
      }
    }
  },
  component: () =>
    import("./backoffice-header-actions-container-widget.component").then(
      (m) => m.BackofficeHeaderActionsContainerWidgetComponent
    )
};
