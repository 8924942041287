import { SocketDtoDirectionEnum, SocketDtoTypeEnum } from "@smallstack/axios-api-client";
import { FORM_INPUT_TYPES } from "@smallstack/form-shared";
import { AllWidgetTags, WidgetRegistryEntry } from "@smallstack/widget-core";

export const OfflineSearchWidget: WidgetRegistryEntry = {
  name: "OfflineSearch",
  configuration: {
    templateName: "Offline Suche",
    templateDescription: "Sucht in der lokalen (offline) Datenbank im Browser",
    tags: AllWidgetTags,
    icon: "search",
    dataSchema: {
      type: "object",
      properties: {
        typePath: {
          title: "Datentyp",
          type: "string",
          "x-schema-form": { widget: FORM_INPUT_TYPES }
        },
        executeOnLoad: {
          title: "Suche beim Laden sofort ausführen",
          type: "boolean",
          default: false,
          description: "Wenn aktiviert, wird die Suche sofort ausgeführt, wenn die Seite geladen wird."
        }
      }
    },
    sockets: [
      { direction: SocketDtoDirectionEnum.Out, name: "models", type: SocketDtoTypeEnum.Objectarray },
      { direction: SocketDtoDirectionEnum.Out, name: "focused", type: SocketDtoTypeEnum.Undefined }
    ]
  },
  component: () => import("./offline-search-widget.component").then((m) => m.OfflineSearchWidgetComponent)
};
