import { WIDGET_FORM_INPUT_COORDINATES } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const CoordinatesInputWidget: WidgetRegistryEntry = {
  name: WIDGET_FORM_INPUT_COORDINATES,
  component: () => import("./coordinates-input-widget.component").then((m) => m.CoordinatesInputWidgetComponent),
  configuration: {
    templateName: "Koordinaten Felder",
    templateDescription: "Zeigt zwei Felder an für Längengrad und Breitengrad.",
    icon: "fa-globe"
  }
};
