import { WIDGET_FILTER_INPUT_NUMBER } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const NumberFilterWidget: WidgetRegistryEntry = {
  name: WIDGET_FILTER_INPUT_NUMBER,
  component: () => import("./number-filter-widget.component").then((m) => m.NumberFilterWidgetComponent),
  configuration: {
    templateName: "Nummern Filter",
    templateDescription: "Filtert nach einer Zahl",
    icon: "filter"
  }
};
