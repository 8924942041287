import { WIDGET_FORM_INPUT_HTML_SIZING } from "@smallstack/typesystem";
import { AllWidgetTags, WidgetRegistryEntry } from "@smallstack/widget-core";

export const IconCardWidget: WidgetRegistryEntry = {
  name: "IconCard",
  configuration: {
    templateName: "Icon Card",
    templateDescription: "Zeigt eine umrandete Komponente mit Icon an",
    icon: "rounded-rectangle",
    tags: AllWidgetTags,
    dataSchema: {
      type: "object",
      properties: {
        title: {
          title: "Titel",
          type: "string"
        },
        subTitle: {
          title: "Untertitel",
          type: "string"
        },
        icon: {
          title: "Icon",
          type: "string",
          "x-schema-form": { widget: "icon" }
        },
        fixedHeight: {
          title: "Feste Höhe",
          description:
            'Kann gesetzt werden, damit nachladender Inhalt die Kartengröße nicht beeinflusst und die UI somit nicht "wackelt".',
          type: "string",
          "x-schema-form": { inputWidget: WIDGET_FORM_INPUT_HTML_SIZING }
        },
        link: {
          title: "Link",
          type: "string"
        }
      }
    }
  },
  component: () => import("./icon-card-widget.component").then((m) => m.IconCardWidgetComponent)
};
