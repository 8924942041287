@if (widgetTreeService.editMode()) {
  <div class="bg-white bg-opacity-75 absolute z-50 top-10 left-0 right-0 bottom-0 center-center p-5">
    <div class="text-2xl">Widget Vorlage</div>
    <div
      >Dies ist eine verwaltete Widget Vorlage. Sobald es Änderungen in der verwalteten Vorlage gibt, werden diese
      automatisch angewandt. Falls Sie dieses Widget bearbeiten möchten, dann wird der aktuelle Inhalt der Vorlage an
      diese Stelle kopiert. Änderungen an der Widget Vorlage werden dann nicht mehr synchronisiert.</div
    >
    <button class="btn btn-primary mt-3" (click)="copyTemplate()">Widget Vorlage kopieren & bearbeiten</button>
  </div>
}
@if (widgetTreeService.editMode()) {
  <smallstack-widget-editor-renderer [widget]="widgetTemplate" [context]="context()" />
} @else {
  <smallstack-widget-renderer [widget]="widgetTemplate" [context]="context()" />
}
