import { WIDGET_FORM_INPUT_MANGO_QUERY } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const MangoQueryInputWidget: WidgetRegistryEntry = {
  name: WIDGET_FORM_INPUT_MANGO_QUERY,
  component: () => import("./mango-query-input-widget.component").then((m) => m.MangoQueryInputWidgetComponent),
  configuration: {
    templateName: "Datenbank Filter Feld",
    templateDescription: "Mit diesen Feldern kann eine Datenbank Abfrage erstellt werden.",
    icon: "database"
  }
};
