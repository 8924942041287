import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const RealEstateSharepointLinkWidget: WidgetRegistryEntry = {
  name: "real-estate-sharepoint-link-widget",
  component: () =>
    import("./real-estate-sharepoint-link-widget.component").then(
      (module) => module.RealEstateSharepointLinkWidgetComponent
    ),
  configuration: {
    templateName: "Real Estate Sharepoint Link Widget",
    templateDescription:
      "This widget is used to display a link to a sharepoint folder where the real estate documents are stored.",
    icon: "fa-house"
  }
};
