import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const FilterFieldWidget: WidgetRegistryEntry = {
  name: "FilterField",
  component: () => import("./filter-field-widget.component").then((m) => m.FilterFieldWidgetComponent),
  configuration: {
    templateName: "Filter",
    templateDescription: "Zeigt ein passendes Feld an, welches zum Filtern verwendet werden kann.",
    icon: "filter"
  }
};
