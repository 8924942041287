import { WidgetRegistry } from "@smallstack/widget-core";
import { AnonymizedStatsWidget } from "./lib/anonymized-stats-widget";
import { ChartWidget } from "./lib/chart-widget";
import { CloneEntityDialogButtonWidget } from "./lib/clone-entity-dialog-button-widget";
import { CmsPageWidget } from "./lib/cms-page-widget";
import { ContextDebuggerWidget } from "./lib/context-debugger-widget";
import { FcmSetupWidget } from "./lib/fcm-setup-widget";
import { GoogleMapsWidget } from "./lib/google-maps-widget";
import { GoogleMapsWidgetV2 } from "./lib/google-maps-widget-v2";
import { HtmlContextWidget } from "./lib/html-context-widget";
import { HubspotButtonWidget } from "./lib/hubspot-button-widget";
import { IconWidget } from "./lib/icon-widget";
import { ImageWidget } from "./lib/image-widget";
import { ImageWidgetV2 } from "./lib/image-widget-v2";
import { MappedIconWidget } from "./lib/mapped-icon-widget";
import { MarkAsReadWidget } from "./lib/mark-as-read-widget";
import { MathWidget } from "./lib/math-widget";
import { RealEstateSharepointLinkWidget } from "./lib/real-estate-sharepoint-link-widget";
import { ServiceWorkerVersionStatusWidget } from "./lib/service-worker-version-status-widget";
import { SmallstackWikiPageWidget } from "./lib/smallstack-wiki-page-widget";
import { TypeEditorWidget } from "./lib/type-editor-widget";
import { TypeEditorWidgetV2 } from "./lib/type-editor-widget-v2";
import { TypeFormWidget } from "./lib/type-form-widget";
import { UnreadIndicatorWidget } from "./lib/unread-indicator-widget";
import { ValueMappingWidget } from "./lib/value-mapping-widget";

export function addOtherWidgets(widgetRegistry: WidgetRegistry): void {
  widgetRegistry.addWidget(AnonymizedStatsWidget);
  widgetRegistry.addWidget(ChartWidget);
  widgetRegistry.addWidget(CloneEntityDialogButtonWidget);
  widgetRegistry.addWidget(CmsPageWidget);
  widgetRegistry.addWidget(ContextDebuggerWidget);
  widgetRegistry.addWidget(GoogleMapsWidget);
  widgetRegistry.addWidget(GoogleMapsWidgetV2);
  widgetRegistry.addWidget(IconWidget);
  widgetRegistry.addWidget(ImageWidget);
  widgetRegistry.addWidget(ImageWidgetV2);
  widgetRegistry.addWidget(MarkAsReadWidget);
  widgetRegistry.addWidget(HubspotButtonWidget);
  widgetRegistry.addWidget(MathWidget);
  widgetRegistry.addWidget(SmallstackWikiPageWidget);
  widgetRegistry.addWidget(TypeEditorWidget);
  widgetRegistry.addWidget(TypeEditorWidgetV2);
  widgetRegistry.addWidget(TypeFormWidget);
  widgetRegistry.addWidget(UnreadIndicatorWidget);
  widgetRegistry.addWidget(ValueMappingWidget);
  widgetRegistry.addWidget(MappedIconWidget);
  widgetRegistry.addWidget(RealEstateSharepointLinkWidget);
  widgetRegistry.addWidget(HtmlContextWidget);
  widgetRegistry.addWidget(ServiceWorkerVersionStatusWidget);
  widgetRegistry.addWidget(FcmSetupWidget);
}
