import { WidgetRegistryEntry, WidgetTag } from "@smallstack/widget-core";

export const VisitorEventsWidget: WidgetRegistryEntry = {
  name: "VisitorEvents",
  configuration: {
    templateName: "Besucher Termine",
    icon: "event",
    templateDescription: "Zeigt die nächsten Besucher Termine mit Name und Uhrzeit an.",
    data: { widgetIcon: "event", widgetTitle: [{ value: "Besucher Termine" }] },
    dataSchema: {
      type: "object",
      properties: {
        entries: {
          type: "string",
          enum: ["1", "3", "5", "10", "15", "20", "25"],
          title: "@@dashboard.widgets.event.numberentries",
          default: "3"
        }
      }
    },
    tags: [WidgetTag.DASHBOARD]
  },
  component: () => import("./visitor-events-widget.component").then((m) => m.VisitorEventsWidgetComponent)
};
