import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const AnalyticsOptInWidget: WidgetRegistryEntry = {
  name: "AnalyticsOptInWidget",
  configuration: {
    icon: "analytics",
    templateName: "Analytics Opt-In",
    templateDescription: "This widget allows the user to opt-in or opt-out of analytics"
  },
  component: () => import("./analytics-opt-in-widget.component").then((m) => m.AnalyticsOptInWidgetComponent)
};
