import { WIDGET_FORM_INPUT_BREAKPOINT } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const BreakpointInputWidget: WidgetRegistryEntry = {
  name: WIDGET_FORM_INPUT_BREAKPOINT,
  component: () => import("./breakpoint-input-widget.component").then((m) => m.BreakpointInputWidgetComponent),
  configuration: {
    templateName: "Breakpoint Feld",
    templateDescription: "Ein Feld, welches verschiedene Werte für verschiedene Bildschirmgrößen annimmt.",
    icon: "fa-up-right-and-down-left-from-center"
  }
};
