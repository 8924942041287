import { WidgetRegistryEntry, WidgetTag } from "@smallstack/widget-core";

export const LastCompanyWidget: WidgetRegistryEntry = {
  name: "LastCompany",
  configuration: {
    templateName: "Letzte Firma",
    icon: "company",
    templateDescription: "Zeigt die zuletzt geöffnete Firma an.",
    tags: [WidgetTag.DASHBOARD]
  },
  component: () => import("./last-company-widget.component").then((m) => m.LastCompanyWidgetComponent)
};
