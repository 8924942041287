import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const HasUnreadSystemMessagesWidget: WidgetRegistryEntry = {
  name: "HasUnreadSystemMessages",
  configuration: {
    icon: "sent",
    templateName: "System Nachrichten vorhanden",
    templateDescription:
      "Zeigt verschiedene Widgets an, je nachdem ob der Benutzer ungelesene Systemnachrichten hat oder nicht.",
    dataSchema: {
      type: "object",
      properties: {
        trueComponent: {
          type: "object",
          title: "Hat ungelesene Systemnachrichten",
          "x-schema-form": {
            widget: "widget"
          }
        },
        falseComponent: {
          type: "object",
          title: "Hat keine ungelesenen Systemnachrichten",
          "x-schema-form": {
            widget: "widget"
          }
        }
      }
    }
  },
  component: () =>
    import("./has-unread-system-messages-widget.component").then((m) => m.HasUnreadSystemMessagesWidgetComponent)
};
