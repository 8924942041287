import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const StringFilterWidget: WidgetRegistryEntry = {
  name: "StringFilter",
  component: () => import("./string-filter-widget.component").then((m) => m.StringFilterWidgetComponent),
  configuration: {
    templateName: "String Filter",
    templateDescription: "Zeigt ein Feld an, welches zum Filtern von Zeichenketten verwendet werden kann.",
    icon: "filter"
  }
};
