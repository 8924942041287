import { WIDGET_FORM_INPUT_FILE_ID } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const FileIdInputWidget: WidgetRegistryEntry = {
  name: WIDGET_FORM_INPUT_FILE_ID,
  component: () => import("./file-id-input-widget.component").then((m) => m.FileIdInputWidgetComponent),
  configuration: {
    templateName: "Datei ID Eingabe",
    templateDescription:
      "Zeigt ein Feld in welches eine Datei ID eingegeben werden kann. Es kann zusätzlich eine Datei ausgewählt werden.",
    icon: "rename"
  }
};
