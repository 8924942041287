import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const ContextDebuggerWidget: WidgetRegistryEntry = {
  name: "ContextDebugger",
  component: () => import("./context-debugger-widget.component").then((m) => m.ContextDebuggerWidgetComponent),
  configuration: {
    templateName: "Kontext Debugger",
    templateDescription:
      "Dieses Widget richtet sich an Entwickler und zeigt den aktuellen Kontext an, in dem sich das Widget befindet",
    icon: "bug"
  }
};
