import { BUTTON_WIDGET_CONFIGURATION_SCHEMA_PROPERTIES } from "@smallstack/core-common";
import { WidgetRegistryEntry, WidgetTag } from "@smallstack/widget-core";

export const LogoutButtonWidget: WidgetRegistryEntry = {
  name: "LogoutButton",
  configuration: {
    dataSchema: {
      properties: BUTTON_WIDGET_CONFIGURATION_SCHEMA_PROPERTIES
    },
    data: {
      buttonText: [{ value: "Abmelden" }],
      btnText: [{ value: "Abmelden" }],
      btnColor: "btn-warning",
      btnSize: "btn-xs"
    },
    icon: "exit",
    templateName: "Abmelde Button",
    templateDescription:
      "Ein Button, welcher nur angezeigt, falls der Benutzer angemeldet ist. Sobald der Button geklickt wird, wird der Benutzer abgemeldet.",
    tags: [WidgetTag.APPLICATION]
  },
  component: () => import("./logout-button-widget.component").then((m) => m.LogoutButtonWidgetComponent)
};
