import { WidgetRegistry } from "@smallstack/widget-core";
import { CalendarWidget } from "./lib/calendar-widget";
import { ConsentWidget } from "./lib/consent-widget";
import { ContextContainerWidget } from "./lib/context-container-widget";
import { ContextManipulationWidget } from "./lib/context-manipulation-widget";
import { ContextSenderWidget } from "./lib/context-sender-widget";
import { ContextTableWidget } from "./lib/context-table-widget";
import { DataButtonWidget } from "./lib/data-button-widget";
import { DataExportWidget } from "./lib/data-export-widget";
import { DataTableWidget } from "./lib/data-table-widget";
import { DataTableWidgetV2 } from "./lib/data-table-widget-v2";
import { DataTableWidgetV3 } from "./lib/data-table-widget-v3";
import { DataTableWidgetV4 } from "./lib/data-table-widget-v4";
import { DefaultDetailsViewWidget } from "./lib/default-details-view-widget";
import { EntityLinkWidget } from "./lib/entity-link-widget";
import { FilesWidget } from "./lib/files-widget";
import { HttpRequestBtnWidget } from "./lib/http-request-btn-widget";
import { OfflineSearchWidget } from "./lib/offline-search-widget";
import { OpenDetailsBtnWidget } from "./lib/open-details-btn-widget";
import { OpenEditorBtnWidget } from "./lib/open-editor-btn-widget";
import { OpenEditorBtnWidgetV2 } from "./lib/open-editor-btn-widget-v2";
import { PatchObjectsButtonWidget } from "./lib/patch-objects-button-widget";
import { PatchObjectsButtonV2Widget } from "./lib/patch-objects-button-widget-v2";
import { ReactiveDataWidget } from "./lib/reactive-data-widget";
import { ReactiveDataWidgetV2 } from "./lib/reactive-data-widget-v2";
import { StartWorkflowButtonWidget } from "./lib/start-workflow-button-widget";
import { StoreConfiguratorWidget } from "./lib/store-configurator-widget";
import { SyncStatusWidget } from "./lib/sync-status-widget";
import { TableWidget } from "./lib/table-widget";
import { ArrayViewWidget } from "./lib/view-widgets/array-view-widget";
import { BooleanViewWidget } from "./lib/view-widgets/boolean-view-widget";
import { CoordinatesViewWidget } from "./lib/view-widgets/coordinates-view-widget";
import { DateTimeViewWidget } from "./lib/view-widgets/date-time-view-widget";
import { I18nViewWidget } from "./lib/view-widgets/i18n-view-widget";
import { ImageViewWidget } from "./lib/view-widgets/image-view-widget";
import { LinksViewWidget } from "./lib/view-widgets/links-view-widget";
import { NumberViewWidget } from "./lib/view-widgets/number-view-widget";
import { ObjectViewWidget } from "./lib/view-widgets/object-view-widget";
import { SelectViewWidget } from "./lib/view-widgets/select-view-widget";
import { StringViewWidget } from "./lib/view-widgets/string-view-widget";
import { TimeSpanViewWidget } from "./lib/view-widgets/time-span-view-widget";

export function addDataWidgets(widgetRegistry: WidgetRegistry): void {
  widgetRegistry.addWidget(ArrayViewWidget);
  widgetRegistry.addWidget(BooleanViewWidget);
  widgetRegistry.addWidget(CalendarWidget);
  widgetRegistry.addWidget(ContextManipulationWidget);
  widgetRegistry.addWidget(ContextTableWidget);
  widgetRegistry.addWidget(ContextContainerWidget);
  widgetRegistry.addWidget(ContextSenderWidget);
  widgetRegistry.addWidget(CoordinatesViewWidget);
  widgetRegistry.addWidget(DataButtonWidget);
  widgetRegistry.addWidget(DataExportWidget);
  widgetRegistry.addWidget(DataTableWidget);
  widgetRegistry.addWidget(DataTableWidgetV2);
  widgetRegistry.addWidget(DataTableWidgetV3);
  widgetRegistry.addWidget(DataTableWidgetV4);
  widgetRegistry.addWidget(DateTimeViewWidget);
  widgetRegistry.addWidget(DefaultDetailsViewWidget);
  widgetRegistry.addWidget(EntityLinkWidget);
  widgetRegistry.addWidget(FilesWidget);
  widgetRegistry.addWidget(HttpRequestBtnWidget);
  widgetRegistry.addWidget(I18nViewWidget);
  widgetRegistry.addWidget(ImageViewWidget);
  widgetRegistry.addWidget(LinksViewWidget);
  widgetRegistry.addWidget(NumberViewWidget);
  widgetRegistry.addWidget(ObjectViewWidget);
  widgetRegistry.addWidget(OfflineSearchWidget);
  widgetRegistry.addWidget(OpenDetailsBtnWidget);
  widgetRegistry.addWidget(OpenEditorBtnWidget);
  widgetRegistry.addWidget(OpenEditorBtnWidgetV2);
  widgetRegistry.addWidget(ReactiveDataWidget);
  widgetRegistry.addWidget(ReactiveDataWidgetV2);
  widgetRegistry.addWidget(SelectViewWidget);
  widgetRegistry.addWidget(StartWorkflowButtonWidget);
  widgetRegistry.addWidget(StoreConfiguratorWidget);
  widgetRegistry.addWidget(StringViewWidget);
  widgetRegistry.addWidget(SyncStatusWidget);
  widgetRegistry.addWidget(TableWidget);
  widgetRegistry.addWidget(TimeSpanViewWidget);
  widgetRegistry.addWidget(PatchObjectsButtonWidget);
  widgetRegistry.addWidget(PatchObjectsButtonV2Widget);
  widgetRegistry.addWidget(ConsentWidget);
}
