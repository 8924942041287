import { WidgetRegistry } from "@smallstack/widget-core";
import { AbsolutelyPositionedContainerWidget } from "./lib/absolutely-positioned-container-widget";
import { AppContainerWidget } from "./lib/app-container-widget";
import { AppContainerWidgetV2 } from "./lib/app-container-widget-v2";
import { ArrayContainerWidget } from "./lib/array-container-widget";
import { ArrayContainerV2Widget } from "./lib/array-container-widget-v2";
import { ArrayContainerV3Widget } from "./lib/array-container-widget-v3";
import { ArrayContextTableWidget } from "./lib/array-context-table-widget";
import { ArrayCountWidget } from "./lib/array-count-widget";
import { ArrayGlobalsTableWidget } from "./lib/array-globals-table-widget";
import { BadgeListWidget } from "./lib/badge-list-widget";
import { BadgeWidget } from "./lib/badge-widget";
import { BooleanContainerWidget } from "./lib/boolean-container-widget";
import { CardWidget } from "./lib/card-widget";
import { CardWidgetV2 } from "./lib/card-widget-v2";
import { CloseDialogButtonWidget } from "./lib/close-dialog-button-widget";
import { DialogWidget } from "./lib/dialog-widget";
import { ExpandableContainerWidget } from "./lib/expandable-container-widget";
import { HeroWidget } from "./lib/hero-widget";
import { IconCardWidget } from "./lib/icon-card-widget";
import { MaxWidthContainerWidgetV2 } from "./lib/max-width-container-widget-v2";
import { OverlayActionButtonWidget } from "./lib/overlay-action-button-widget";
import { StickyContainerWidget } from "./lib/sticky-container-widget";
import { TabNavigationWidget } from "./lib/tab-navigation-widget";
import { TabNavigationWidgetV2 } from "./lib/tab-navigation-widget-v2";
import { TabsWidget } from "./lib/tabs-widget";
import { TabsWidgetV2 } from "./lib/tabs-widget-v2";
import { TopNavigationWidget } from "./lib/top-navigation-widget";

export function addLayoutWidgets(widgetRegistry: WidgetRegistry): void {
  widgetRegistry.addWidget(AbsolutelyPositionedContainerWidget);
  widgetRegistry.addWidget(AppContainerWidget);
  widgetRegistry.addWidget(AppContainerWidgetV2);
  widgetRegistry.addWidget(ArrayContainerV2Widget);
  widgetRegistry.addWidget(ArrayContainerV3Widget);
  widgetRegistry.addWidget(ArrayContainerWidget);
  widgetRegistry.addWidget(ArrayContextTableWidget);
  widgetRegistry.addWidget(ArrayCountWidget);
  widgetRegistry.addWidget(ArrayGlobalsTableWidget);
  widgetRegistry.addWidget(BadgeListWidget);
  widgetRegistry.addWidget(BadgeWidget);
  widgetRegistry.addWidget(BooleanContainerWidget);
  widgetRegistry.addWidget(CardWidget);
  widgetRegistry.addWidget(CardWidgetV2);
  widgetRegistry.addWidget(CloseDialogButtonWidget);
  widgetRegistry.addWidget(DialogWidget);
  widgetRegistry.addWidget(ExpandableContainerWidget);
  widgetRegistry.addWidget(IconCardWidget);
  widgetRegistry.addWidget(OverlayActionButtonWidget);
  widgetRegistry.addWidget(TabNavigationWidget);
  widgetRegistry.addWidget(TabNavigationWidgetV2);
  widgetRegistry.addWidget(TabsWidget);
  widgetRegistry.addWidget(TabsWidgetV2);
  widgetRegistry.addWidget(StickyContainerWidget);
  widgetRegistry.addWidget(TopNavigationWidget);
  widgetRegistry.addWidget(HeroWidget);
  widgetRegistry.addWidget(MaxWidthContainerWidgetV2);
}
