import { WidgetRegistryEntry, WidgetTag } from "@smallstack/widget-core";

export const BackofficeHelpButtonWidget: WidgetRegistryEntry = {
  name: "BackofficeHelpButton",
  configuration: {
    templateName: "Backoffice Help Button",
    icon: "help",
    templateDescription: "Erlaubt das öffnen der Hilfsseite",
    tags: [WidgetTag.CBO]
  },
  component: () =>
    import("./backoffice-help-button-widget.component").then((m) => m.BackofficeHelpButtonWidgetComponent)
};
