import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const AutoLogoutWidget: WidgetRegistryEntry = {
  name: "AutoLogout",
  configuration: {
    icon: "exit",
    templateName: "Auto Abmeldung",
    templateDescription: "Sobald dieses Widget gerendert wird, wird der Benutzer automatisch abgemeldet."
  },
  component: () => import("./auto-logout-widget.component").then((m) => m.AutoLogoutButtonWidgetComponent)
};
