import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const ChangePasswordWidget: WidgetRegistryEntry = {
  name: "ChangePassword",
  component: () => import("./change-password-widget.component").then((m) => m.ChangePasswordWidgetComponent),
  configuration: {
    templateName: "Change Password",
    icon: "fa-lock"
  }
};
