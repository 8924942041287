import { WIDGET_FORM_INPUT_HTML_SIZING } from "@smallstack/typesystem";
import { WidgetRegistryEntry, WidgetTag } from "@smallstack/widget-core";

export const AppContainerWidgetV2: WidgetRegistryEntry = {
  name: "AppContainerV2",
  configuration: {
    templateName: "Application Layout Container",
    icon: "applications",
    templateDescription:
      "Ein Application Layout welches oben eine Navbar und unten einen Footer anzeigt und in der Mitte den Inhalt der aktuellen Seite darstellt.",
    tags: [WidgetTag.APPLICATION],
    dataSchema: {
      type: "object",
      properties: {
        headerHeight: {
          type: "string",
          title: "Menüleiste Höhe",
          description: "Die Höhe der Menüleiste oben",
          default: "64px",
          "x-schema-form": {
            inputWidget: WIDGET_FORM_INPUT_HTML_SIZING
          }
        },
        footerHeight: {
          type: "string",
          title: "Tabbar Höhe",
          description: "Die Höhe der Tab Bar unten",
          default: "64px",
          "x-schema-form": {
            inputWidget: WIDGET_FORM_INPUT_HTML_SIZING
          }
        },
        padding: {
          type: "string",
          title: "Außenabstand",
          default: "12px",
          description: "Abstand des Containers zu inneren Elementen",
          "x-schema-form": { inputWidget: WIDGET_FORM_INPUT_HTML_SIZING }
        },
        headerComponent: {
          type: "object",
          title: "Komponente für Header",
          "x-schema-form": { widget: "widget" }
        },
        aboveMainComponent: {
          type: "object",
          title: "Komponente über dem Hauptinhalt",
          "x-schema-form": { widget: "widget" }
        },
        belowMainComponent: {
          type: "object",
          title: "Komponente unter dem Hauptinhalt",
          "x-schema-form": { widget: "widget" }
        },
        footerComponent: {
          type: "object",
          title: "Komponente für Footer",
          "x-schema-form": { widget: "widget" }
        }
      }
    }
  },
  component: () => import("./app-container-widget-v2.component").then((m) => m.AppContainerWidgetV2Component)
};
