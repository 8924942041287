import { SocketDtoDirectionEnum, SocketDtoTypeEnum } from "@smallstack/axios-api-client";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const ContextSenderWidget: WidgetRegistryEntry = {
  name: "ContextSender",
  component: () => import("./context-sender-widget.component").then((m) => m.ContextSenderWidgetComponent),
  configuration: {
    templateName: "Kontext Sender",
    templateDescription: "Sendet Daten vom aktuellen Kontext via Socket an andere Widgets",
    icon: "fa-arrow-right-to-arc",
    sockets: [{ direction: SocketDtoDirectionEnum.Out, type: SocketDtoTypeEnum.Object, name: "contextVariableOut" }],
    dataSchema: {
      type: "object",
      properties: {
        contextVariable: {
          title: "Kontext Variable",
          description:
            "Definieren Sie hier, welcher Wert vom aktuellen Kontext gesendet werden soll. Leer lassen für den kompletten Kontext.",
          type: "string"
        }
      }
    }
  }
};
