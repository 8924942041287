import { WIDGET_FORM_INPUT_I18N } from "@smallstack/typesystem";
import { WidgetRegistryEntry, WidgetTag } from "@smallstack/widget-core";

export const FilesWidget: WidgetRegistryEntry = {
  name: "Files",
  configuration: {
    templateName: "Dateien",
    templateDescription: "Zeigt Dateien an, welche auf dem aktuellen Kontext liegen",
    icon: "folder-invoices--v1",
    dataSchema: {
      type: "object",
      properties: {
        contextProperty: {
          title: "Kontext Variable",
          description: "Geben Sie hier an, wo sich die Dateien im aktuellen Kontext befinden",
          type: "string",
          default: "fileIds"
        },
        noFilesFoundText: {
          title: "Keine Dateien gefunden Text",
          description: "Wird angezeigt, wenn keine Dateien gefunden wurden",
          type: "array",
          "x-schema-form": {
            inputWidget: WIDGET_FORM_INPUT_I18N
          }
        }
      }
    },
    tags: [WidgetTag.APPLICATION, WidgetTag.CBO, WidgetTag.CMS]
  },
  component: () => import("./files-widget.component").then((m) => m.FilesWidgetComponent)
};
