import { WIDGET_FILTER_INPUT_DATE } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const DateFilterWidget: WidgetRegistryEntry = {
  name: WIDGET_FILTER_INPUT_DATE,
  component: () => import("./date-filter-widget.component").then((m) => m.DateFilterWidgetComponent),
  configuration: {
    templateName: "Datum Filter",
    templateDescription: "Zeigt Felder an, welche zum Filtern von einem Datum verwendet werden können.",
    icon: "filter"
  }
};
