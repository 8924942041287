import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const PasswordResetWidget: WidgetRegistryEntry = {
  name: "PasswordReset",
  configuration: {
    icon: "user",
    templateName: "Passwort Wiederherstellung",
    templateDescription:
      "Zeigt Formulare an, mit denen ein Benutzer den Passwort Wiederherstellungsprozess starten bzw. beenden kann."
  },
  component: () => import("./password-reset-widget.component").then((m) => m.PasswordResetWidgetComponent)
};
