import { SocketDtoDirectionEnum, SocketDtoTypeEnum } from "@smallstack/axios-api-client";
import { AllWidgetTags, WidgetRegistryEntry } from "@smallstack/widget-core";

export const CreateNoteWidget: WidgetRegistryEntry = {
  name: "CreateNote",
  configuration: {
    templateName: "Notiz erstellen",
    templateDescription: "Mit diesem Widget können Notizen erstellt werden.",
    dataSchema: {
      type: "object",
      properties: {
        targetId: {
          type: "string",
          title: "Id der Ziel Entität",
          description: "An welche Entität soll die Notiz gehängt werden?"
        },
        targetType: {
          type: "string",
          title: "Typ der Ziel Entität",
          description: "Geben Sie hier den Datentyp an",
          "x-schema-form": {
            widget: "types"
          }
        }
      }
    },
    icon: "note",
    tags: AllWidgetTags,
    sockets: [{ direction: SocketDtoDirectionEnum.Out, name: "noteCreated", type: SocketDtoTypeEnum.Object }]
  },
  component: () => import("./create-note-widget.component").then((m) => m.CreateNoteWidgetComponent)
};
