import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const OpenDetailsBtnWidget: WidgetRegistryEntry = {
  name: "OpenDetailsBtn",
  component: () => import("./open-details-btn-widget.component").then((m) => m.OpenDetailsBtnWidgetComponent),
  configuration: {
    templateName: "Details öffnen Button",
    templateDescription: "Öffnet einen Dialog mit Details einer Entität.",
    icon: "task",
    dataSchema: {
      type: "object",
      properties: {
        type: {
          type: "string",
          title: "Datentyp",
          "x-schema-form": {
            widget: "types"
          }
        },
        contextProperty: {
          type: "string",
          title: "Kontext Variable"
        },
        icon: {
          type: "string",
          title: "Icon",
          "x-schema-form": {
            widget: "icon"
          }
        },
        text: {
          type: "string",
          title: "Text"
        },
        btnAsBlock: {
          type: "boolean",
          title: "Button auf voller Breite"
        }
      }
    }
  }
};
