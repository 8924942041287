import { WIDGET_FORM_INPUT_HTML_SIZING } from "@smallstack/typesystem";
import { WidgetRegistryEntry, WidgetTag } from "@smallstack/widget-core";

export const AppContainerWidget: WidgetRegistryEntry = {
  name: "AppContainer",
  configuration: {
    deprecated: true,
    templateName: "Application Layout Container",
    icon: "applications",
    templateDescription:
      "Ein Application Layout welches oben eine Navbar und unten einen Footer anzeigt und in der Mitte den Inhalt der aktuellen Seite darstellt.",
    tags: [WidgetTag.APPLICATION],
    dataSchema: {
      type: "object",
      properties: {
        headerHeight: {
          type: "string",
          title: "Menüleiste Höhe",
          description: "Die Höhe der Menüleiste oben",
          default: "64px",
          "x-schema-form": {
            inputWidget: WIDGET_FORM_INPUT_HTML_SIZING
          }
        },
        footerHeight: {
          type: "string",
          title: "Tabbar Höhe",
          description: "Die Höhe der Tab Bar unten",
          default: "64px",
          "x-schema-form": {
            inputWidget: WIDGET_FORM_INPUT_HTML_SIZING
          }
        },
        backgroundColor: {
          type: "string",
          title: "Hintergrundfarbe der App",
          description: "Zieht sich durch alle Seiten und kann pro Seite überschrieben werden",
          "x-schema-form": {
            widget: "color"
          }
        },
        padding: {
          type: "string",
          title: "Außenabstand",
          default: "12px",
          description: "Abstand des Containers zu inneren Elementen",
          "x-schema-form": { inputWidget: WIDGET_FORM_INPUT_HTML_SIZING }
        }
      }
    }
  },
  component: () => import("./app-container-widget.component").then((m) => m.AppContainerWidgetComponent)
};
