import { ICON_THEMES } from "@smallstack/core-common";
import { AllWidgetTags, WidgetRegistryEntry } from "@smallstack/widget-core";

export const IconWidget: WidgetRegistryEntry = {
  name: "Icon",
  configuration: {
    templateName: "Icon",
    icon: "large-icons",
    templateDescription: "Zeigt ein Icon an",
    dataSchema: {
      type: "object",
      properties: {
        name: {
          title: "Name",
          description: "Der Name des Icons, z.B. 'car' oder 'house'",
          type: "string",
          "x-schema-form": {
            widget: "icon"
          }
        },
        theme: {
          title: "Theme",
          description: "Das Icon Theme, z.B. 'fluent' oder 'ios'",
          type: "string",
          default: "userSetting",
          enum: ["userSetting", ...ICON_THEMES]
        },
        size: {
          title: "Größe",
          description: "Die Größe des Icons",
          type: "string",
          default: "24",
          enum: ["10", "12", "16", "20", "24", "32", "48", "64", "72", "96"]
        },
        color: {
          title: "Farbe",
          description: "Die Farbe des Icons",
          type: "string",
          "x-schema-form": { widget: "color" }
        }
      }
    },
    tags: AllWidgetTags
  },
  component: () => import("./icon-widget.component").then((m) => m.IconWidgetComponent)
};
