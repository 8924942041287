import { WidgetRegistry } from "@smallstack/widget-core";
import { AbsenceRequestsWidget } from "./lib/absence-requests-widget";
import { EmployeeModulesGridWidget } from "./lib/employee-modules-grid-widget";
import { TimeTrackingWidget } from "./lib/time-tracking-widget";

export function addEmployeeWidgets(widgetRegistry: WidgetRegistry): void {
  widgetRegistry.addWidget(AbsenceRequestsWidget);
  widgetRegistry.addWidget(EmployeeModulesGridWidget);
  widgetRegistry.addWidget(TimeTrackingWidget);
}
