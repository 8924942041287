import { WidgetRegistryEntry, WidgetTag } from "@smallstack/widget-core";

export const AuthorizedContainerWidget: WidgetRegistryEntry = {
  name: "AuthorizedContainer",
  configuration: {
    icon: "security-checked--v1",
    tags: [WidgetTag.APPLICATION, WidgetTag.CBO, WidgetTag.CMS],
    templateName: "Autorisierter Container",
    templateDescription: "Ein Widget, welches seinen Inhalt nur angemeldeten Benutzern anzeigt."
  },
  component: () => import("./authorized-container-widget.component").then((m) => m.AuthorizedContainerWidgetComponent)
};
