import { SocketDtoDirectionEnum, SocketDtoTypeEnum } from "@smallstack/axios-api-client";
import { WIDGET_FORM_INPUT_HTML_SIZING } from "@smallstack/typesystem";
import { AllWidgetTags, WidgetRegistryEntry } from "@smallstack/widget-core";

export const ArrayContainerV2Widget: WidgetRegistryEntry = {
  name: "ArrayContainerV2",
  configuration: {
    templateName: "Array Container V2",
    icon: "grid-2",
    templateDescription: "Ein Widget, welches pro Eintrag einer gegebenen Liste ein weiteres Widget anzeigt",
    dataSchema: {
      type: "object",
      properties: {
        contextProperty: {
          type: "string",
          title: "Kontext Liste",
          description:
            "Kontext Variable, in der die Liste an Einträgen gefunden werden kann. Leer lassen, falls der Context selbst die Liste beinhaltet."
        },
        component: {
          title: "Komponente",
          type: "object",
          "x-schema-form": { widget: "cms" }
        },
        flexLayout: {
          title: "Richtung",
          type: "string",
          default: "row wrap",
          enum: ["row", "row wrap", "column", "column wrap"]
        },
        gap: {
          title: "Zwischenabstand",
          type: "string",
          default: "12px",
          "x-schema-form": { inputWidget: WIDGET_FORM_INPUT_HTML_SIZING }
        },
        emptyTemplate: {
          title: "Keine Elemente gefunden",
          description: "Diese Komponente wird angezeigt, falls keine Elemente gegeben sind.",
          type: "object",
          "x-schema-form": { widget: "widget" }
        }
      }
    },
    tags: AllWidgetTags,
    sockets: [{ direction: SocketDtoDirectionEnum.In, name: "models", type: SocketDtoTypeEnum.Objectarray }],
    deprecated: true,
    deprecatedMessage: [{ value: "Bitte den neuen Array Container verwenden" }],
    deprecatedActions: [
      {
        buttonTitle: [{ value: "Zu V3 migrieren" }],
        migrationFn: async (oldData) => {
          return {
            newWidgetName: "ArrayContainerV3",
            newWidgetData: {
              contextProperty: oldData.contextProperty,
              component: oldData.component,
              flexLayout: oldData.flexLayout?.startsWith("column") ? "col" : "row",
              gap: oldData.gap ? parseInt(oldData.gap) / 3 : 2,
              wrap: (oldData.flexLayout as string)?.includes("wrap") ? true : false,
              emptyTemplate: oldData.emptyTemplate
            }
          };
        }
      }
    ]
  },
  component: () => import("./array-container-widget-v2.component").then((m) => m.ArrayContainerWidgetV2Component)
};
