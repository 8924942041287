import { WIDGET_FORM_INPUT_BOOLEAN } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const BooleanInputWidget: WidgetRegistryEntry = {
  name: WIDGET_FORM_INPUT_BOOLEAN,
  component: () => import("./boolean-input-widget.component").then((m) => m.BooleanInputWidgetComponent),
  configuration: {
    templateName: "Boolean Feld",
    templateDescription: "Zeigt ein ja/nein bzw. wahr/falsch Feld an.",
    icon: "fa-square-check"
  }
};
