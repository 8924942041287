import { WIDGET_FORM_INPUT_DATE } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const DateInputWidget: WidgetRegistryEntry = {
  name: WIDGET_FORM_INPUT_DATE,
  component: () => import("./date-input-widget.component").then((m) => m.DateInputWidgetComponent),
  configuration: {
    templateName: "Datumsfeld",
    templateDescription: "Zeigt ein Datumsfeld an.",
    icon: "rename"
  }
};
