import { WIDGET_FORM_INPUT_TYPE_DESCRIPTOR } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const TypeDescriptorInputWidget: WidgetRegistryEntry = {
  name: WIDGET_FORM_INPUT_TYPE_DESCRIPTOR,
  component: () => import("./type-descriptor-input-widget.component").then((m) => m.TypeDescriptorInputWidgetComponent),
  configuration: {
    templateName: "Type Descriptor Feld",
    templateDescription: "In diesem Feld kann ein Datentyp mit Variante ausgewählt werden.",
    icon: "fa-database"
  }
};
