import { WIDGET_FILTER_INPUT_DATE_TIME } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const DateTimeFilterWidget: WidgetRegistryEntry = {
  name: WIDGET_FILTER_INPUT_DATE_TIME,
  component: () => import("./date-time-filter-widget.component").then((m) => m.DateTimeFilterWidgetComponent),
  configuration: {
    templateName: "Datum & Uhrzeit Filter",
    templateDescription: "Zeigt Felder an, welche zum Filtern von einem Datum mit Uhrzeit verwendet werden können.",
    icon: "filter"
  }
};
