import { WIDGET_FORM_INPUT_HTML_SIZING } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const StickyContainerWidget: WidgetRegistryEntry = {
  name: "StickyContainer",
  configuration: {
    templateName: "Sticky Container",
    icon: "grid",
    templateDescription: "1-3 Widgets untereinander, wobei das obere und das untere Widget fixiert sind.",
    dataSchema: {
      type: "object",
      properties: {
        height: {
          type: "string",
          title: "Höhe des Containers",
          default: "100vh",
          "x-schema-form": {
            inputWidget: WIDGET_FORM_INPUT_HTML_SIZING
          }
        },
        topWidgetHeight: {
          type: "string",
          title: "Höhe des oberen Widgets",
          default: "80px",
          "x-schema-form": {
            inputWidget: WIDGET_FORM_INPUT_HTML_SIZING
          }
        },
        topWidgetBackgroundColor: {
          type: "string",
          title: "Hintergrundfarbe des oberen Widgets",
          default: "#ffffff",
          "x-schema-form": {
            widget: "color"
          }
        },
        topWidget: {
          type: "object",
          title: "Oberes Widget",
          "x-schema-form": {
            widget: "widget"
          }
        },
        contentWidgetBackgroundColor: {
          type: "string",
          title: "Hintergrundfarbe des Inhaltswidgets",
          default: "#ffffff",
          "x-schema-form": {
            widget: "color"
          }
        },
        contentWidget: {
          type: "object",
          title: "Inhaltswidget",
          "x-schema-form": {
            widget: "widget"
          }
        },
        bottomWidgetHeight: {
          type: "string",
          title: "Höhe des unteren Widgets",
          default: "80px",
          "x-schema-form": {
            inputWidget: WIDGET_FORM_INPUT_HTML_SIZING
          }
        },
        bottomWidgetBackgroundColor: {
          type: "string",
          title: "Hintergrundfarbe des unteren Widgets",
          default: "#ffffff",
          "x-schema-form": {
            widget: "color"
          }
        },
        bottomWidget: {
          type: "object",
          title: "Unteres Widget",
          "x-schema-form": {
            widget: "widget"
          }
        }
      }
    }
  },
  component: () => import("./sticky-container-widget.component").then((m) => m.StickyContainerWidgetComponent)
};
