import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const SystemMessagesContainerWidget: WidgetRegistryEntry = {
  name: "SystemMessagesContainer",
  configuration: {
    templateName: "System Nachrichten",
    templateDescription: "Zeigt alle System Nachrichten chronologisch an.",
    icon: "sent",
    ssr: false,
    dataSchema: {
      type: "object",
      properties: {
        messageWidget: {
          type: "object",
          title: "Inhaltswidget",
          description: "Diesem Widget werden alle Nachrichten per Context bereitgestellt.",
          "x-schema-form": {
            widget: "widget"
          }
        }
      }
    }
  },
  component: () =>
    import("./system-messages-container-widget.component").then((m) => m.SystemMessagesContainerWidgetComponent)
};
