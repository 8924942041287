import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const DefaultDetailsViewWidget: WidgetRegistryEntry = {
  name: "DefaultDetailsView",
  component: () => import("./default-details-view-widget.component").then((m) => m.DefaultDetailsViewWidgetComponent),
  configuration: {
    templateName: "Detail Anzeige",
    templateDescription: "Zeigt Details einer Entität an."
  }
};
