import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const SchedulingRuleDisplayWidget: WidgetRegistryEntry = {
  name: "SchedulingRuleDisplay",
  configuration: {
    templateName: "Erstellungsregel",
    templateDescription: "Zeigt eine Erstellungsregel und deren nächste Termine an.",
    icon: "calendar",
    dataSchema: {
      type: "object",
      properties: {
        property: {
          type: "string",
          title: "Eigenschaft",
          description: "Die Eigenschaft, welche die Erstellungsregel (v1) enthält."
        }
      }
    }
  },
  component: () =>
    import("./scheduling-rule-display-widget.component").then((m) => m.SchedulingRuleDisplayWidgetComponent)
};
