import { ErrorHandler, Injectable } from "@angular/core";
import * as Sentry from "@sentry/angular-ivy";

@Injectable({ providedIn: "root" })
export class ErrorHandlerService implements ErrorHandler {
  private errors: { [key: string]: number } = {};

  public handleError(error: Error | any): void {
    const errorText = error.message || error.toString();
    if (!this.errors[errorText]) {
      this.errors[errorText] = 0;
    }
    this.errors[errorText]++;
    if (this.errors[errorText] >= 100) {
      console.error(`Error ${errorText} happened 100 times!`);
      // eslint-disable-next-line no-debugger
      debugger;
      throw new Error(`Error ${errorText} happened 100 times!`);
    }
    // only report the first time to avoid spamming Sentry
    if (this.errors[errorText] === 1) {
      Sentry.captureException(error);
    }

    console.error(error);
  }
}
