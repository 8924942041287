import { WIDGET_FORM_INPUT_AUTO_GEOLOCATION } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const AutoGeolocationInputWidget: WidgetRegistryEntry = {
  name: WIDGET_FORM_INPUT_AUTO_GEOLOCATION,
  component: () =>
    import("./auto-geolocation-input-widget.component").then((m) => m.AutoGeolocationInputWidgetComponent),
  configuration: {
    templateName: "Auto Geolocation Feld",
    templateDescription: "Zeigt ein Feld, welches automatisch die Geolocation des Benutzers ermittelt.",
    icon: "fa-location-crosshairs"
  }
};
