import { SocketDtoDirectionEnum, SocketDtoTypeEnum } from "@smallstack/axios-api-client";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const TodoTemplateOwnerReplacementEditorWidget: WidgetRegistryEntry = {
  name: "TodoTemplateOwnerReplacementEditor",
  configuration: {
    icon: "sorting-arrows-horizontal",
    templateName: "Aufgaben Vorlagen Vertretungseditor",
    templateDescription: "Hier können Sie Vertretungen für Aufgaben Vorlagen definieren.",
    dataSchema: {
      type: "object",
      properties: {}
    },
    sockets: [
      { name: "save", direction: SocketDtoDirectionEnum.In, type: SocketDtoTypeEnum.Undefined },
      { name: "validate", type: SocketDtoTypeEnum.Undefined, direction: SocketDtoDirectionEnum.In }
    ]
  },
  component: () =>
    import("./todo-template-owner-replacement-editor-widget.component").then(
      (m) => m.TodoTemplateOwnerReplacementEditorWidgetComponent
    )
};
