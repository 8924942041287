import { WIDGET_FORM_INPUT_TEXTAREA } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const TextareaInputWidget: WidgetRegistryEntry = {
  name: WIDGET_FORM_INPUT_TEXTAREA,
  component: () => import("./textarea-input-widget.component").then((m) => m.TextareaInputWidgetComponent),
  configuration: {
    templateName: "Mehrzeiliges Texteingabefeld",
    templateDescription: "Zeigt ein mehrzeiliges Feld in welches eine Zeichenkette eingegeben werden kann.",
    icon: "rename"
  }
};
