import { SocketDtoDirectionEnum, SocketDtoTypeEnum } from "@smallstack/axios-api-client";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const CreateUserWidget: WidgetRegistryEntry = {
  name: "CreateUserEditor",
  component: () => import("./create-user-widget.component").then((m) => m.CreateUserWidgetComponent),
  configuration: {
    templateName: "Benutzer/Kunde Editor",
    templateDescription: "Zeigt einen Editor für Benutzer und Kunden.",
    icon: "user",
    sockets: [{ direction: SocketDtoDirectionEnum.In, name: "save", type: SocketDtoTypeEnum.Undefined }],
    dataSchema: {
      type: "object",
      properties: {
        userContextVariable: {
          type: "string",
          title: "Benutzer Kontext Variable"
        }
      }
    }
  }
};
