import { WIDGET_FORM_INPUT_STRING } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const StringInputWidget: WidgetRegistryEntry = {
  name: WIDGET_FORM_INPUT_STRING,
  component: () => import("./string-input-widget.component").then((m) => m.StringInputWidgetComponent),
  configuration: {
    templateName: "Zeichenketten Feld",
    templateDescription: "Zeigt ein Feld in welches eine Zeichenkette eingegeben werden kann.",
    icon: "rename"
  }
};
